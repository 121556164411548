import Vue from 'vue'
import {
  fetchFishBatchDetails,
  fetchFishBatches,
  createFishBatch,
  updateFishBatchById,
  deleteFishBatchById
} from '@/modules/core/api/fishBatch'

export default {
  namespaced: true,
  state: {
    all: [],
    detailsByIds: {},
    isLoading: false
  },
  getters: {
    names: state => {
      return state.all.reduce((names, fishBatch) => {
        names[fishBatch.id] = fishBatch.name
        return names
      }, {})
    },
    labels: state => {
      const labels = {}
      for (const fishBatchId in state.detailsByIds) {
        labels[fishBatchId] = state.detailsByIds[fishBatchId].label
      }
      return labels
    },
    byIds: state => {
      return state.all.reduce((acc, fishBatch) => {
        acc[fishBatch.id] = fishBatch
        return acc
      }, {})
    }
  },
  mutations: {
    SET_ALL: (state, all) => {
      state.all = all
    },
    SET_DETAILS: (state, details) => {
      for (const item of details) {
        Vue.set(state.detailsByIds, item.id, item)
      }
    },
    SET_IS_LOADING: (state, isLoading) => {
      state.isLoading = isLoading
    }
  },
  actions: {
    async fetchFishBatchDetails ({ commit }, fishBatchIds) {
      try {
        const { data } = await fetchFishBatchDetails(fishBatchIds)
        commit('SET_DETAILS', data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async fetchFishBatches ({ commit }) {
      try {
        commit('SET_IS_LOADING', true)
        const { data } = await fetchFishBatches()
        commit('SET_ALL', data)
      } catch (e) {
        return Promise.reject(e)
      } finally {
        commit('SET_IS_LOADING', false)
      }
    },
    async createFishBatch ({ commit, dispatch }, fishBatch) {
      try {
        commit('SET_IS_LOADING', true)
        const { data: fishBatchId } = await createFishBatch(fishBatch)
        await dispatch('fetchFishBatches')
        return fishBatchId
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit('SET_IS_LOADING', false)
      }
    },
    async updateFishBatchById ({ commit, dispatch }, { id, data }) {
      try {
        commit('SET_IS_LOADING', true)
        await updateFishBatchById(id, data)
        await dispatch('fetchFishBatches')
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit('SET_IS_LOADING', false)
      }
    },
    async deleteFishBatchById ({ commit, dispatch }, id) {
      try {
        commit('SET_IS_LOADING', true)
        await deleteFishBatchById(id)
        await dispatch('fetchFishBatches')
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit('SET_IS_LOADING', false)
      }
    }
  }
}
