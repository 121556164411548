import Vue from 'vue'
import { fetchMomentMetricsByTanks } from '@/modules/core/api/indicators'
import { verifyFishMetricsAreValid } from '@/modules/core/use/fishMetrics'

export default {
  namespaced: true,
  state: {
    isLoadingFishMetrics: false,
    fishMetricsByTanks: {
      timestamp: null,
      data: {}
    },
    isLoadingTemperature: false,
    temperatureByTanks: {
      timestamp: null,
      data: {}
    },
    isLoadingFeeding: false,
    feedingByTanks: {
      timestamp: null,
      data: {}
    },
    isLoadingDegreeday: false,
    degreedayByTanks: {
      timestamp: null,
      data: {}
    }
  },
  getters: {
    fishMetricsByTanks: (state, getters, rootState, rootGetters) => {
      return rootGetters['factory/tanks/forUser'].reduce((acc, tank) => {
        acc[tank.id] = verifyFishMetricsAreValid(
          state.fishMetricsByTanks.data[tank.id]
        )
          ? state.fishMetricsByTanks.data[tank.id]
          : {
            fishAmount: null,
            fishBiomass: null,
            fishWeight: null
          }
        return acc
      }, {})
    },
    fishAmountByTanks: (state, getters, rootState, rootGetters) => {
      return rootGetters['factory/tanks/forUser'].reduce((acc, tank) => {
        acc[tank.id] = getters.fishMetricsByTanks[tank.id]?.fishAmount ?? null
        return acc
      }, {})
    },
    fishBiomassByTanks: (state, getters, rootState, rootGetters) => {
      return rootGetters['factory/tanks/forUser'].reduce((acc, tank) => {
        acc[tank.id] = getters.fishMetricsByTanks[tank.id]?.fishBiomass ?? null
        return acc
      }, {})
    },
    fishWeightByTanks: (state, getters, rootState, rootGetters) => {
      return rootGetters['factory/tanks/forUser'].reduce((acc, tank) => {
        acc[tank.id] = getters.fishMetricsByTanks[tank.id]?.fishWeight ?? null
        return acc
      }, {})
    },
    temperatureByTanks: (state, getters, rootState, rootGetters) => {
      return rootGetters['factory/tanks/forUser'].reduce((acc, tank) => {
        acc[tank.id] = state.temperatureByTanks.data[tank.id] ?? null
        return acc
      }, {})
    },
    feedingByTanks: (state, getters, rootState, rootGetters) => {
      return rootGetters['factory/tanks/forUser'].reduce((acc, tank) => {
        acc[tank.id] = state.feedingByTanks.data[tank.id] ?? null
        return acc
      }, {})
    },
    degreedayByTanks: (state, getters, rootState, rootGetters) => {
      return rootGetters['factory/tanks/forUser'].reduce((acc, tank) => {
        acc[tank.id] = state.degreedayByTanks.data[tank.id] ?? null
        return acc
      }, {})
    }
  },
  mutations: {
    RESET_ALL_METRICS: (state) => {
      const resetMetric = metric => {
        Vue.set(state, metric, { timestamp: null, data: {}})
      }
      resetMetric('fishMetricsByTanks')
      resetMetric('temperatureByTanks')
      resetMetric('feedingByTanks')
      resetMetric('degreedayByTanks')
    },
    TOGGLE_LOADING_FISH_METRICS: (state, isLoading) => {
      Vue.set(state, 'isLoadingFishMetrics', isLoading)
    },
    SET_TANKS_FISH_METRICS: (state, { timestamp, data }) => {
      Vue.set(state, 'fishMetricsByTanks', { timestamp, data })
    },
    TOGGLE_LOADING_TEMPERATURE: (state, isLoading) => {
      Vue.set(state, 'isLoadingTemperature', isLoading)
    },
    SET_TANKS_TEMPERATURE: (state, { timestamp, data }) => {
      Vue.set(state, 'temperatureByTanks', { timestamp, data })
    },
    TOGGLE_LOADING_FEEDING: (state, isLoading) => {
      Vue.set(state, 'isLoadingFeeding', isLoading)
    },
    SET_TANKS_FEEDING: (state, { timestamp, data }) => {
      Vue.set(state, 'feedingByTanks', { timestamp, data })
    },
    TOGGLE_LOADING_DEGREEDAY: (state, isLoading) => {
      Vue.set(state, 'isLoadingDegreeday', isLoading)
    },
    SET_TANKS_DEGREEDAY: (state, { timestamp, data }) => {
      Vue.set(state, 'degreedayByTanks', { timestamp, data })
    }
  },
  actions: {
    async fetchFishMetricsByTanksAt ({ state, commit }, timestamp) {
      commit('TOGGLE_LOADING_FISH_METRICS', true)
      try {
        const metrics = ['fishChange']
        const response = await fetchMomentMetricsByTanks(timestamp, metrics)
        const data = {}
        for (const tankId in response.data) {
          data[tankId] = response.data[tankId]?.fishChange ?? null
        }
        commit('SET_TANKS_FISH_METRICS', { timestamp, data })
        return data
      } catch (e) {
        return Promise.reject(e)
      } finally {
        commit('TOGGLE_LOADING_FISH_METRICS', false)
      }
    },
    async fetchTemperatureByTanksAt ({ state, commit }, timestamp) {
      const isLoading = state.isLoadingTemperature
      const localData = state.temperatureByTanks
      if (!isLoading && localData.timestamp === timestamp) {
        return localData.data
      }
      commit('TOGGLE_LOADING_TEMPERATURE', true)
      try {
        const metrics = ['temperature']
        const response = await fetchMomentMetricsByTanks(timestamp, metrics)
        const data = {}
        for (const tankId in response.data) {
          data[tankId] = response.data[tankId]?.temperature?.indicator ?? null
        }
        commit('SET_TANKS_TEMPERATURE', { timestamp, data })
        return data
      } catch (e) {
        return Promise.reject(e)
      } finally {
        commit('TOGGLE_LOADING_TEMPERATURE', false)
      }
    },
    async fetchFeedingByTanksAt ({ state, commit }, timestamp) {
      const isLoading = state.isLoadingFeeding
      const localData = state.feedingByTanks
      if (!isLoading && localData.timestamp === timestamp) {
        return localData.data
      }
      commit('TOGGLE_LOADING_FEEDING', true)
      try {
        const metrics = ['notEmptyFeeding']
        const response = await fetchMomentMetricsByTanks(timestamp, metrics)
        const data = {}
        for (const tankId in response.data) {
          data[tankId] = response.data[tankId]?.notEmptyFeeding ?? null
        }
        commit('SET_TANKS_FEEDING', { timestamp, data })
        return data
      } catch (e) {
        return Promise.reject(e)
      } finally {
        commit('TOGGLE_LOADING_FEEDING', false)
      }
    },
    async fetchDegreedayByTanksAt ({ state, commit }, timestamp) {
      const isLoading = state.isLoadingDegreeday
      const localData = state.degreedayByTanks
      if (!isLoading && localData.timestamp === timestamp) {
        return localData.data
      }
      commit('TOGGLE_LOADING_DEGREEDAY', true)
      try {
        const metrics = ['degreeday']
        const response = await fetchMomentMetricsByTanks(timestamp, metrics)
        const data = {}
        for (const tankId in response.data) {
          data[tankId] = response.data[tankId]?.degreeday ?? null
        }
        commit('SET_TANKS_DEGREEDAY', { timestamp, data })
        return data
      } catch (e) {
        return Promise.reject(e)
      } finally {
        commit('TOGGLE_LOADING_DEGREEDAY', false)
      }
    }
  }
}
