import request from '@/utils/request'

const PREFIX = '/api/core/indicators'

export function saveIndicators (indicators) {
  return request({
    url: `${PREFIX}`,
    method: 'post',
    data: indicators
  })
}

export function updateIndicator (payload) {
  return request({
    url: `${PREFIX}/${payload.id}`,
    method: 'put',
    data: payload
  })
}

export function getTankFishCycleDetailsIndicatorsInPeriod (tankId, periodStart, periodEnd) {
  return request({
    url: `${PREFIX}/fish-cycle/${tankId}`,
    method: 'get',
    params: {
      start: periodStart,
      end: periodEnd
    }
  })
}

export function findIndicatorsByFilter (payload) {
  return request({
    url: `${PREFIX}/filter`,
    method: 'post',
    data: payload
  })
}

export function fetchTankFishAmountInPeriod ({ tankId, periodStart, periodEnd }) {
  return request({
    url: `${PREFIX}/fish/amount`,
    method: 'get',
    params: {
      tankId: tankId,
      start: periodStart,
      end: periodEnd
    }
  })
}

export function fetchTankFishWeightInPeriod ({ tankId, periodStart, periodEnd }) {
  return request({
    url: `${PREFIX}/fish/weight`,
    method: 'get',
    params: {
      tankId: tankId,
      start: periodStart,
      end: periodEnd
    }
  })
}

export function fetchTankMortalityInPeriod ({ tankId, periodStart, periodEnd }) {
  return request({
    url: `${PREFIX}/fish/mortality`,
    method: 'get',
    params: {
      tankId: tankId,
      start: periodStart,
      end: periodEnd
    }
  })
}

export function fetchMortalityInPeriodByTankIds ({ tankIds, periodStart, periodEnd }) {
  return request({
    url: `${PREFIX}/fish/mortality/tanks`,
    method: 'post',
    data: {
      tankIds,
      start: periodStart,
      end: periodEnd
    }
  })
}

export function fetchFishCatchHistoryInPeriod ({ tankId, siteId, periodStart, periodEnd }) {
  return request({
    url: `${PREFIX}/fish/catch`,
    method: 'get',
    params: {
      tankId: tankId,
      siteId: siteId,
      start: periodStart,
      end: periodEnd
    }
  })
}

export function fetchFishCatchInPeriodByTankIds ({ tankIds, periodStart, periodEnd }) {
  return request({
    url: `${PREFIX}/fish/catch/tanks`,
    method: 'post',
    data: {
      tankIds,
      start: periodStart,
      end: periodEnd
    }
  })
}

export function fetchFishSalesBetween (start, end, tankId) {
  return request({
    url: `${PREFIX}/fish/sales`,
    method: 'get',
    params: {
      tankId,
      start,
      end
    }
  })
}

export function fetchTankFishSeedingInPeriod ({ tankId, periodStart, periodEnd }) {
  return request({
    url: `${PREFIX}/fish/seeding`,
    method: 'get',
    params: {
      tankId: tankId,
      start: periodStart,
      end: periodEnd
    }
  })
}

export function fetchFeedingInPeriodByTankIds ({ tankIds, periodStart, periodEnd }) {
  return request({
    url: `${PREFIX}/filter`,
    method: 'post',
    data: {
      filters: {
        tankIds,
        type: 'feeding',
        period: [periodStart, periodEnd]
      },
      sort: {
        timestamp: 1
      }
    }
  })
}

export function fetchWaterTemperatureMapInPeriod ({ periodStart, periodEnd }) {
  return request({
    url: `${PREFIX}/temperature-map`,
    method: 'get',
    params: {
      start: periodStart,
      end: periodEnd
    }
  })
}

export function fetchTanksDegreedayInPeriod ({ periodStart, periodEnd }) {
  return request({
    url: `${PREFIX}/degreeday/tanks`,
    method: 'get',
    params: {
      start: periodStart,
      end: periodEnd
    }
  })
}

export function fetchTankDegreedayInPeriod ({ tankId, periodStart, periodEnd }) {
  return request({
    url: `${PREFIX}/degreeday/tank`,
    method: 'get',
    params: {
      tankId: tankId,
      start: periodStart,
      end: periodEnd
    }
  })
}

export function deleteIndicator (id) {
  return request({
    url: `${PREFIX}/${id}`,
    method: 'delete'
  })
}

export function fetchMomentMetricsByTanks (timestamp, metrics) {
  const params = {
    timestamp
  }
  if (Array.isArray(metrics)) {
    params.metrics = metrics
  }
  return request({
    url: `${PREFIX}/tank`,
    method: 'get',
    params
  })
}

export function fetchMomentMetricsByTank (tankId, timestamp, metrics) {
  const params = {
    timestamp
  }
  if (Array.isArray(metrics)) {
    params.metrics = metrics
  }
  return request({
    url: `${PREFIX}/tank/${tankId}`,
    method: 'get',
    params
  })
}

export function fetchMortalitySummaryInPeriod ({ periodStart, periodEnd }) {
  return request({
    url: `${PREFIX}/summary/mortality`,
    method: 'get',
    params: {
      start: periodStart,
      end: periodEnd
    }
  })
}

export function fetchCatchSummaryInPeriod ({ periodStart, periodEnd }) {
  return request({
    url: `${PREFIX}/summary/catch`,
    method: 'get',
    params: {
      start: periodStart,
      end: periodEnd
    }
  })
}
