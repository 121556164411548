import request from '@/utils/request'

const PREFIX = '/api/core/fish-batch'

export function fetchFishBatchDetails (fishBatchIds) {
  return request({
    url: `${PREFIX}/details`,
    method: 'post',
    data: { fishBatchIds }
  })
}

export function fetchFishBatches () {
  return request({
    url: PREFIX,
    method: 'get'
  })
}

export function createFishBatch (data) {
  return request({
    url: PREFIX,
    method: 'post',
    data
  })
}

export function updateFishBatchById (id, data) {
  return request({
    url: `${PREFIX}/${id}`,
    method: 'put',
    data
  })
}

export function deleteFishBatchById (id) {
  return request({
    url: `${PREFIX}/${id}`,
    method: 'delete'
  })
}
